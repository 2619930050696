import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as firebase from "firebase/app";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  line: number;
  navLinks = [false, false, false, false];
  appInstalled: boolean = true;
  screenTrace: firebase.performance.Trace;

  deferredPrompt;
  ngOnInit() { 
    this.screenTrace = firebase.performance().trace("AppComponent");
    this.screenTrace.start();
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.appInstalled = false;
      return false;
    });
    
    // To determine app is installed or not
    window.addEventListener('appinstalled', (evt) => {
      this.appInstalled = true;
    });
  }

  close(): void {
    this.appInstalled = true;
  }

  showPrompt() {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          this.appInstalled = true;
          console.log('User accepted the A2HS prompt');
        } else {
          this.appInstalled = false;
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = false;
      });
  }
  constructor(private router: Router) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((x: NavigationEnd) => {
      switch (x.url) {
        case "/":
          this.line = -380;
          this.navLinks[0] = true;
          document.querySelectorAll("a.navItem").forEach((e, k) => k == 0 ? e.setAttribute("active", "true") : e.setAttribute("active", "false"));
          break;
        case "/experiments":
          this.line = -285;
          //this.navLinks.every(()=>false);
          this.navLinks[1] = true;
          document.querySelectorAll("a.navItem").forEach((e, k) => k == 1 ? e.setAttribute("active", "true") : e.setAttribute("active", "false"));
          break;
        case "/projects":
          this.line = -190;
          this.navLinks.every(() => false);
          document.querySelectorAll("a.navItem").forEach((e, k) => k == 2 ? e.setAttribute("active", "true") : e.setAttribute("active", "false"));
          break;
        case "/settings":
          this.line = -95;
          this.navLinks.every(() => false);
          document.querySelectorAll("a.navItem").forEach((e, k) => k == 3 ? e.setAttribute("active", "true") : e.setAttribute("active", "false"));
          break;
        default:
          this.line = -9999;
          this.navLinks.every(() => false);
          document.querySelectorAll("a.navItem").forEach((e, k) => e.setAttribute("active", "false"));
      }
    });
  }
  ngOnDestroy() {
    this.screenTrace.stop();
  }
}
