import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from './home/home.component';

import { AngularFireModule } from '@angular/fire';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFirePerformanceModule } from '@angular/fire/performance';

const appRoutes: Routes = [
  // Home
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "experiments",
    loadChildren: () => import("./experiment/experiment.module").then(m => m.ExperimentModule)
  },
  {
    path: "projects",
    loadChildren: () => import("./project/project.module").then(m => m.ProjectModule)
  },
  {
    path: "settings",
    loadChildren: () => import("./setting/setting.module").then(m => m.SettingsModule)
  },
  {
    path: "**",
    loadChildren: () => import("./notfound/notfound.module").then(m => m.NotfoundModule)
  }
];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment),
    AngularFirePerformanceModule,
    RouterModule.forRoot(
      appRoutes
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
