import { Component, OnInit, OnDestroy } from '@angular/core';
import * as firebase from "firebase/app";
import "firebase/performance";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  homeperf = firebase.performance();
  trace:firebase.performance.Trace = this.homeperf.trace("HomeComponent");

  constructor() { }

  ngOnInit() {
    this.trace.start();
  }
  ngOnDestroy() {
    this.trace.stop();
  }
}
